import React, { useState } from 'react'
import { string } from 'prop-types'

import { Popover } from '@mmb/ui-components'

import { UpdateModal, ResendButton, DeleteModal, ShowActionsButton } from './components'

import styles from './MoreActions.module.css'

MoreActions.propTypes = {
  email: string.isRequired,
  nom: string.isRequired,
  prenom: string.isRequired,
  id: string.isRequired,
}

export function MoreActions({ nom, prenom, email, id, ...userInfo }) {
  const [open, setOpen] = useState(false)

  return (
    <Popover
      className={styles.moreActions__popover}
      title="Modification ou suppression d'un utilisateur"
      open={open}
      hideTip
      position="left"
      content={(
        <div className={styles.moreActions__childrenPopover}>
          <UpdateModal onClose={() => setOpen(false)} nom={nom} prenom={prenom} email={email} personneId={id} {...userInfo} />
          <ResendButton onClose={() => setOpen(false)} id={id} />
          <DeleteModal onClose={() => setOpen(false)} id={id} email={email} />
          <ShowActionsButton label="close menu" onClick={() => setOpen(false)} />
        </div>
      )}
      wrapperClassName={styles.moreActions__popoverLeft}
    >
      {!open && <ShowActionsButton onClick={() => setOpen(true)} />}
    </Popover>
  )
}
