import React from 'react'
import { arrayOf, func, oneOf, string } from 'prop-types'

import { ModalTrigger } from '@core/partenaire-ui'
import { ADMINISTRATEUR, COLLABORATEUR, SUPER_ADMIN } from '@core/common'

import { Form } from '../../Form'
import { UpdateButton } from './updateButton'

UpdateModal.propTypes = {
  onClose: func.isRequired,
  email: string.isRequired,
  nom: string.isRequired,
  prenom: string.isRequired,
  personneId: string.isRequired,
  role: oneOf([COLLABORATEUR, ADMINISTRATEUR, SUPER_ADMIN]),
  cabinets: arrayOf(string.isRequired),
  agences: arrayOf(string.isRequired),
}

UpdateModal.defaultProps = {
  role: COLLABORATEUR,
  cabinets: [],
  agences: [],
}

export function UpdateModal({ onClose, email, nom, prenom, personneId, role, cabinets, agences }) {
  return (
    <ModalTrigger
      renderButton={(_, setModalOpen) => <UpdateButton setModalOpen={setModalOpen} />}
      onClose={onClose}
    >
      <Form
        title="Modification d'un utilisateur"
        submitLabel="Modifier"
        initialValues={{
          email,
          nom,
          prenom,
          role,
          cabinets,
          agences,
        }}
        onClose={onClose}
        personneId={personneId}
      />
    </ModalTrigger>
  )
}
