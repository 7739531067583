import React, { useEffect, useMemo } from 'react'
import { useParams, Navigate } from '@core/routing'
import { Separator } from '@core/partenaire-ui'
import { PORTAIL_PARTENAIRE } from '@core/common'
import { useIdentite } from '@core/partenaire-profil'

import { trackOnChange } from '@core/analytics'
import { OutilsPanel } from './panel'
import { OutilsDocuments } from './documents'
import { useOutilsRubriques } from './Outils.hooks'

import styles from './Outils.module.css'

export const Outils = () => {
  const { identifiant } = useIdentite()
  useEffect(() => {
    if (identifiant) {
      trackOnChange('Outils', identifiant)
    }
  }, [identifiant])


  const { rubriqueId } = useParams()
  const { rubriques, loading } = useOutilsRubriques()
  const isPortailPartenaire = global.GATSBY_PORTAL === PORTAIL_PARTENAIRE

  const selectedRubrique = useMemo(() => rubriques && rubriques.find(r => r.id === rubriqueId), [rubriqueId, rubriques])

  if (!rubriqueId && rubriques && rubriques.length > 0) {
    const firstRubrique = rubriques[0]
    return <Navigate to={`/app/outils/${firstRubrique.id}`} />
  }

  return (
    <div className={styles.outils}>
      <OutilsPanel rubriques={rubriques} loading={loading} />

      {!loading && (
      <main className={styles.outils__main}>
        <h2>{selectedRubrique?.nom}</h2>
        <Separator />
        {rubriqueId && <OutilsDocuments rubriqueId={rubriqueId} withGroupe={isPortailPartenaire} withFavoris={isPortailPartenaire} />}
      </main>)}
    </div>
  )
}
