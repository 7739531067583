import { BaseSyntheticEvent, useContext, useEffect, useRef } from 'react'
import InfiniteLoader from 'react-window-infinite-loader'
import { gql, usePaginationQuery, useMutation, useQueryWithError } from '@core/graphql'
import { searchContext, useHasPermission, useUtilisateurMultiPartenaire } from '@core/partenaire-common'

import { toast } from '@core/ui'
import { Agence, ColumnName, DossierResume, DossierStatut, Gamme } from '@partenaire/common'
import { SEARCH_DOSSIERS_QUERY_NAME } from '@partenaire/frontend-common'
import { GraphqlPaginationRequestArguments } from '@core/common-frontend'
import { PageRequest, PermissionScope } from '@core/common'


const GET_DOSSIERS = gql`
query getDossiers($pageRequest: partenaire_PageRequestInput, $agences: [String], $search: String, $gamme: partenaire_Gamme, $statut: partenaire_TypeStatut) {
  ${SEARCH_DOSSIERS_QUERY_NAME}(pageRequest: $pageRequest, agences: $agences, search: $search, gamme: $gamme, statut: $statut) {
    pageNumber
    pageSize
    counters {
      total
    }
    elements {
      id
      emprunteurs {
        id
        identite {
          nom
          prenom
          businessId
        }
      }
      reference
      statut
      dateStatut
      montantFinance
      isPriority
      gamme
      signatureType
      health
    }
  }
}
`

export const DELETE_DOSSIER = gql`
  mutation ($id: ID!) {
    partenaire_deleteDossier(id: $id){
        id
    }
  }
`

const GET_AGENCES = gql`
query getAgencesReference {
  partenaire_agences {
    id
    nom
    referenceExterne
  }
}
`

interface DossiersPageRequestArguments extends GraphqlPaginationRequestArguments {
  agences?: string[]
  search?: string
  gamme?: Gamme
  statut?: DossierStatut
}

export interface Column {
  name: ColumnName
  label?: string
}
export function useGetPage(
  agences?: string[],
  sortBy?: ColumnName,
  search?: string,
  selectedGamme?: Gamme,
  isDescendingOrder?: boolean,
  statut?: DossierStatut | '',
) {
  const pageRequest: PageRequest<ColumnName> = {
    pageNumber: 1,
    pageSize: 20,
    sortBy: sortBy ?? ColumnName?.DATE_STATUT,
    descendingOrder: isDescendingOrder,
  }

  return usePaginationQuery<DossiersPageRequestArguments, DossierResume>(GET_DOSSIERS, 'partenaire_dossiers', {
    variables: { pageRequest, agences, search, gamme: selectedGamme, statut: statut || undefined },
    notifyOnNetworkStatusChange: true,
  })
}

const toSearchAgencesParams =
  (searchedAgences: Agence[], allAgencesList: Agence[] | undefined, hasPermissionDossierReadAll: boolean, loading: boolean) : string[] | undefined => {
    if (!allAgencesList || loading) {
      return undefined
    }
    const allAgences = !hasPermissionDossierReadAll ? allAgencesList : []
    return (searchedAgences.length === 0 ? allAgences : searchedAgences).map(agence => agence.referenceExterne)
  }

export function useDossiers() {
  const hasPermissionDossierWrite = useHasPermission(PermissionScope.DOSSIER_WRITE)
  const hasPermissionDossierReadAll = useHasPermission(PermissionScope.DOSSIER_READ_ALL)
  const {
    search: [search],
    sort: [sort, setSort],
    isDescendingOrder: [isDescendingOrder, setIsDescendingOrder],
    agences: [agences, setAgences],
    selectedGamme: [selectedGamme],
    selectedAgences: [selectedAgences],
    statut: [statut],
    totalCount: [, setTotalCount],
  } = useContext(searchContext)

  useUtilisateurMultiPartenaire()
  const criteriasChangedRef = useRef(false)
  const listRef = useRef<InfiniteLoader>(null)
  const { data: agenceData, loading: agenceLoading } = useQueryWithError(GET_AGENCES, { notifyOnNetworkStatusChange: true })
  const pageDossiers = useGetPage(
    toSearchAgencesParams(selectedAgences, agences, hasPermissionDossierReadAll, agenceLoading),
    sort,
    search,
    selectedGamme,
    isDescendingOrder,
    statut,
  )

  const loading = pageDossiers?.loading || agenceLoading

  useEffect(() => {
    if (agenceLoading) {
      return
    }
    setAgences(agenceData?.partenaire_agences)
  }, [agenceData])

  useEffect(() => {
    criteriasChangedRef.current = true
  }, [search, sort, isDescendingOrder, agences, selectedAgences, selectedGamme, statut])

  useEffect(() => {
    if (pageDossiers?.counters?.total !== undefined) {
      setTotalCount(pageDossiers?.counters?.total)
    }
  }, [pageDossiers?.counters?.total])

  useEffect(() => {
    if (listRef.current && !pageDossiers?.loading) {
      listRef.current.resetloadMoreItemsCache(true)
      listRef.current.scrollTo(0)
    }
  }, [pageDossiers?.loading, criteriasChangedRef])

  const [deleteDossier, { loading: deleteLoading }] = useMutation(DELETE_DOSSIER, {
    refetchQueries: [{ query: GET_DOSSIERS, variables: { pageRequest: { pageNumber: 1, pageSize: 20 }, agences, emprunteur: sort } }],
    onCompleted: () => {
      toast({ message: 'Un dossier a été supprimé.', type: 'SUCCESS' })
    },
    onError: () => {
      toast({ message: 'La suppression d’un dossier n’a pas pu être effectuée, veuillez recommencer.', type: 'ERROR' })
    },
  })

  const maxItemDisplayed = 15
  const totalCount = pageDossiers?.counters?.total ?? 0
  const dossiers = pageDossiers?.elements ?? []
  const displayabledCount = maxItemDisplayed < totalCount ? maxItemDisplayed : totalCount
  const hasNextPage = Boolean(pageDossiers?.elements && pageDossiers?.counters && pageDossiers?.elements.length < pageDossiers?.counters.total)

  const toggleSort = (columnName : ColumnName) => (event: BaseSyntheticEvent) => {
    if (columnName === sort && !isDescendingOrder) {
      setSort('')
    } else if (columnName === sort && isDescendingOrder) {
      setIsDescendingOrder(false)
    } else {
      setIsDescendingOrder(true)
      setSort(columnName)
    }
    event.preventDefault()
  }

  return {
    totalCount,
    dossiers,
    loading,
    deleteDossier: (id: string) => deleteDossier({ variables: { id } }),
    deleteLoading,
    hasPermissionDossierWrite,
    fetchMore: () => pageDossiers?.fetchMore ? pageDossiers?.fetchMore() : Promise.resolve(),
    displayabledCount,
    hasNextPage,
    listRef,
    sort,
    isDescendingOrder,
    toggleSort,
  }
}
