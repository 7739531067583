import React, { useContext, useEffect } from 'react'
import classNames from 'classnames'
import { useParams } from '@core/routing'
import { Panel } from '@core/partenaire-ui'
import { WarningPopIn } from '@partenaire/frontend-ui'
import { useIdentite } from '@core/partenaire-profil'

import { FromPage, trackOnChange, trackOnClick } from '@core/analytics'
import { Filigrane } from './Filigrane'
import { PreSaisie } from './PreSaisie'
import { Form } from './Form'
import { useSaisie, usePrint } from './Contexts'
import { GammeButtons } from './Buttons'
import { TransmissionHandler, useTransmissionHandler } from './TransmissionHandler'

import styles from './Saisie.module.css'
import { FormStateContext } from './Contexts/FormStateContext'
import { SaisiePropsUI } from '../model'
import { useGamme } from './Gamme'

export function Saisie() {
  const { identifiant } = useIdentite()
  trackOnChange('Saisie', identifiant!)

  const { dossierId } : { dossierId: string } = useParams()
  const { saisieUI, updateSaisie } = useSaisie()
  const { props: { hasCoEmprunteur, gamme } } = saisieUI
  const { changeGamme, isUpdatingGamme } = useGamme()
  const formState = useContext(FormStateContext)
  const { isEnAttente, loading } = useTransmissionHandler()
  // eslint-disable-next-line no-empty-pattern
  const [{}, onPrint] = usePrint()
  useEffect(() => {
    if (dossierId) {
      updateSaisie({ dossierId } as SaisiePropsUI)
    } else {
      formState.updateFormStateShowErrors(false)
    }
  }, [dossierId])

  if (loading) return null
  if (isEnAttente) return (<TransmissionHandler />)
  return (
    <div className={styles.saisie}>
      <Panel className={styles.saisie__panel}>
        {saisieUI.isDossierLoaded() && <Filigrane gamme={gamme} hasCoEmprunteur={hasCoEmprunteur} />}
      </Panel>
      <main role="main" className={classNames(styles.saisie__main, { [styles.saisie__print]: onPrint })}>
        <header className={styles.saisie__bandeau}>
          <h2 className={styles.saisie__title}>
            Saisie de dossier
          </h2>
          {saisieUI.isDossierLoaded() && (
            <div className={styles.saisie__bandeau}>
              <WarningPopIn
                onConfirm={() => {
                  trackOnClick('bouton switch gamme - formulaire', FromPage.SAISIE, gamme)
                  changeGamme()
                }}
                label="Changement de gamme"
                title="Changement de gamme"
                warningLine={`Souhaitez-vous basculer votre dossier en ${gamme === 'SECURED' ? 'sans garantie' : 'hypothécaire'} ?`}
                warningLineBold="Merci de confirmer."
                loading={isUpdatingGamme}
                confirmText="Confirmer"
                loadingText="Changement en cours"
              >
                <GammeButtons gamme={gamme} small />
              </WarningPopIn>
            </div>
          )}
        </header>
        {saisieUI.isDossierLoaded() ? <Form /> : <PreSaisie />}
      </main>
    </div>
  )
}
