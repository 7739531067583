import React from 'react'
import { string } from 'prop-types'

import { Panel } from '@core/partenaire-ui'
import { trackOnChange } from '@core/analytics'
import { useIdentite } from '@core/partenaire-profil'

import { Information } from './Information'
import { usePanel } from './DetailPanel.hooks'
import { DetailPanelLink } from './DetailPanelLink'

import styles from './DetailPanel.module.css'
import { Interlocuteur } from './Interlocuteur/Interlocuteur'

DetailPanel.propTypes = {
  id: string.isRequired,
}

export function DetailPanel({ id }) {
  const { identifiant } = useIdentite()
  trackOnChange('Detail dossier', identifiant)

  const { loading, links, replaceReference } = usePanel(id)
  return (
    <Panel
      className={styles.detailPanel}
      aria-label="menu de la page détail"
    >
      {loading || !links ? null :
        (
          <div>
            <Information className={styles.detailPanel__informations} id={id} />
            <Interlocuteur id={id} />
            {links.map(({ linkPattern, label }) => (
              <DetailPanelLink key={label} linkPattern={linkPattern} label={label} replaceReference={replaceReference} />
            ))}
          </div>
        )}
    </Panel>
  )
}
