import { Separator } from '@core/partenaire-ui'
import { Bullet } from '@mmb/ui-components'
import { string } from 'prop-types'
import React from 'react'

import styles from './Interlocuteur.module.css'
import { useEmprunteursAssurance } from '../../../Assurances/Assurances.hooks'

Interlocuteur.propTypes = {
  id: string.isRequired,
}


export function Interlocuteur({ id }) {
  const { interlocuteur } = useEmprunteursAssurance(id)

  if (!interlocuteur?.nom) {
    return (
      <div />
    )
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          <Bullet className={styles.bullet} />
          <div className={styles.title__text}> PRIS EN CHARGE PAR </div>
        </div>
        <div className={styles.interlocuteur__info}> {interlocuteur.nom} </div>
        <div className={styles.interlocuteur__info}> {interlocuteur.email} </div>
        <div className={styles.interlocuteur__info}> {interlocuteur.telephone} </div>
      </div>
      <Separator className={styles.separator} />
    </>
  )
}
