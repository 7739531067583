import React from 'react'

export function DossierKOIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.08044 8.41003H9.28603M7.08044 6.53003H12.6492M7.08044 18.9831H16.857M7.08044 17.0272H16.857"
        stroke="#D13C2F"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.9799 3H5V12.7029L7.37765 10.7012L9.66145 12.7029L12.0078 10.7012L14.2916 12.7029L16.5911 10.7012L19 12.7029V6.927M14.9799 3V6.88115H19V6.927M14.9799 3L19 6.927M19 21H5V15.2394L7.37765 13.0849L9.66145 15.0407L12.0078 13.0849L14.2916 15.0407L16.5911 13.0849L19 15.2394V21Z" stroke="#234E91" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}
